<template>
  <el-container direction="vertical">
    <Header></Header>
    <el-main>
      <!--内容-->
      <div class="content">
        <!--登录表单-->
        <div class="register">
          <h1>用户注册</h1>
          <el-form label-position="left" label-width="80px">
            <el-form-item label="用户名">
              <el-input v-model="register_data.user_name" />
            </el-form-item>
            <el-form-item label="邮箱">
              <el-input v-model="register_data.email" />
            </el-form-item>
            <el-form-item label="密码">
              <el-input v-model="register_data.password" type="password" show-password/>
            </el-form-item>
            <el-form-item label="确认密码">
              <el-input v-model="register_data.confirm_password" type="password" show-password/>
            </el-form-item>
            <el-form-item label="验证码">
              <el-input style="width: 78%; margin-right: 10px;" v-model="register_data.verify_code"/>
              <el-button type="primary" style="width: 20%" @click="obtain_verify_code">{{ verify_code_button.text }}</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="register_check">注册</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-main>
    <Footer></Footer>
  </el-container>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
import {email_check, error_hint, success_hint, warning_hint, jump_page} from "../tool/tools";
import {obtain_mail, user_register} from "../api/api";
import md5 from "js-md5";

export default {
  name: "Register",
  data(){
    return {
      register_data: {
        user_name: null,
        email: null,
        password: null,
        confirm_password: null,
        verify_code: null
      },
      // 验证码按钮
      verify_code_button: {
        text: "获取验证码",
        total_time: 60,
        is_click: false
      }
    }
  },
  methods: {
    jump_page,
    register_check(){
      let email_toekn = window.sessionStorage.getItem("X-Token")
      // 判断用户名是否输入
      if(this.register_data.user_name === null || this.register_data.user_name === ""){
        warning_hint("请输入用户名");
        return false;
      }
      // 判断邮箱是否输入
      if(this.register_data.email === null || this.register_data.email === ""){
        warning_hint("请输入邮箱");
        return false;
      }
      // 判断邮箱格式
      if(!email_check(this.register_data.email)){
        warning_hint("请输入正确的邮箱");
        return false;
      }
      // 判断密码是否输入
      if(this.register_data.password === null || this.register_data.password === ""){
        warning_hint("请输入密码");
        return false;
      }
      // 判断确认密码是否输入
      if(this.register_data.confirm_password === null || this.register_data.confirm_password === ""){
        warning_hint("请输入确认密码");
        return false;
      }
      // 判断验证码是否输入
      if(this.register_data.verify_code === null || this.register_data.verify_code === ""){
        warning_hint("请输入验证码");
        return false;
      }
      // 判断密码和确认密码
      if(this.register_data.password !== this.register_data.confirm_password){
        warning_hint("密码和确认密码密码不一致");
        return false;
      }
      // 判断是否获取验证码
      if(email_toekn === null || email_toekn === ""){
        warning_hint("请获取验证码");
        return false;
      }
      this.register();
    },
    register(){
      user_register({
        user_name: this.register_data.user_name,
        email: this.register_data.email,
        password: md5(this.register_data.password).toUpperCase(),
        verify_code: this.register_data.verify_code
      }).then(result => {
        success_hint("注册成功");
        this.jump_page("/login");
        console.log(result);
      });
    },
    // 获取验证码
    obtain_verify_code(){
      // 判断邮箱是否输入
      if(this.register_data.email === null || this.register_data.email === ""){
        warning_hint("请输入邮箱");
        return false;
      }
      // 判断邮箱格式
      if(!email_check(this.register_data.email)){
        warning_hint("请输入正确的邮箱");
        return false;
      }
      if(!this.verify_code_button.is_click){
        this.verify_code_button.text = "获取ing";
        this.verify_code_button.is_click = true;
        obtain_mail({
          mode: "register",
          email: this.register_data.email
        }).then(result => {
          let response = result.Data;
          success_hint("邮件发送成功");
          this.verify_code_countdown();
          // 将数据存储在本地
          sessionStorage.setItem("X-Token", response["X-Token"]);
        }).catch(() => {
          error_hint("邮件获取失败, 请重新获取");
          this.verify_code_button.is_click = false;
          this.verify_code_button.text = "获取验证码";
        });
      }else {
        warning_hint("请不要重复点击");
      }
    },
    //验证码倒计时
    verify_code_countdown(){
      let clock = window.setInterval(() => {
        this.verify_code_button.total_time --;
        this.verify_code_button.text = this.verify_code_button.total_time + "s";
        if(this.verify_code_button.total_time <= 0){
          window.clearInterval(clock);
          this.verify_code_button.text = "重新获取";
          this.verify_code_button.total_time = 60;
          this.verify_code_button.is_click = false;
        }
      }, 1000);
    }
  },
  components: {
    Header,
    Footer
  }
}
</script>

<style scoped>
@import "../static/universal/index.css";
.register{
  width: 600px;
  background: white;
  margin: 20px auto;
  padding: 30px;
  border-radius: 8px;
}
</style>